var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',{staticClass:"combination_test_content"},[_c('div',{staticClass:"combination_culum_content culum_bg"},[_c('div',{staticClass:"combination_sub_title"},[_vm._v("科目")]),_vm._l((_vm.subjectList),function(subject){return _c('div',{key:subject.subject_id,staticClass:"culumn_content",class:{
          content_active: _vm.testSelectData.subject_id == subject.subject_id,
        },on:{"click":function($event){return _vm.changeCulumnOrVersion('subject_id', subject.subject_id)}}},[_vm._v(" "+_vm._s(subject.subject_name)+" ")])})],2),_c('div',{staticClass:"combination_culum_content"},[_c('div',{staticClass:"combination_sub_title"},[_vm._v("版本")]),_vm._l((_vm.hsdVersionList),function(version){return _c('div',{key:version.version_id,staticClass:"culumn_content",class:{
          content_active: _vm.testSelectData.version_id == version.version_id,
        }},[_vm._v(" "+_vm._s(version.version_name)+" ")])})],2),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.unitloading),expression:"unitloading"}],staticClass:"combination_sub_content"},[_c('div',{staticClass:"combination_sub_title"},[_vm._v("類別/單元")]),_c('el-checkbox-group',{staticClass:"checkboxGroup",on:{"change":_vm.changeProductClass},model:{value:(_vm.class_id_list),callback:function ($$v) {_vm.class_id_list=$$v},expression:"class_id_list"}},_vm._l((_vm.classList),function(classes){return _c('div',{key:classes.class_id,staticClass:"checkbox_subject"},[_c('el-checkbox',{attrs:{"label":classes.class_id}},[_vm._v(" "+_vm._s(classes.class_name)+" ")]),(
              _vm.selectedClassId == classes.class_id &&
              _vm.classUnitList[_vm.selectedClassId]
            )?_c('div',{staticClass:"checkbox_child_subject"},[_c('el-checkbox-group',{staticClass:"checkboxGroup",on:{"change":_vm.changeClassUnit},model:{value:(_vm.unit_id_list),callback:function ($$v) {_vm.unit_id_list=$$v},expression:"unit_id_list"}},_vm._l((_vm.classUnitList[_vm.selectedClassId]),function(unit){return _c('el-checkbox',{key:unit.unit_id,attrs:{"label":unit.unit_id}},[_vm._v(" "+_vm._s(unit.unit_name)+" ")])}),1)],1):_vm._e()],1)}),0)],1),_c('div',{staticClass:"combination_topic_content"},[_c('div',{staticClass:"combination_sub_title"},[_vm._v("主題")]),_c('div',{staticClass:"topic_content"},_vm._l((_vm.topicList),function(topic){return _c('div',{key:topic.topic_id,staticClass:"topic_child_content",class:{ topic_active: _vm.topic_list.includes(topic.topic_id) },on:{"click":function($event){return _vm.changeTopic(topic.topic_id)}}},[_c('div',[_vm._v(" "+_vm._s(topic.topic_name)),_c('br'),_c('i',{staticClass:"el-icon-success"})])])}),0)]),_c('div',{staticClass:"combination_degree_content"},[_c('div',{staticClass:"group_btn"},_vm._l((_vm.diffcultBtn),function(item){
            var _obj;
return _c('div',{key:item.value,staticClass:"question_degree_btn"},[_c('el-button',{class:( _obj = {}, _obj[("difficult-" + (item.value))] = true, _obj.difficult_active = _vm.testParams.difficult == item.value, _obj ),on:{"click":function($event){return _vm.changeDegree(item.value)}}},[_vm._v(_vm._s(item.name))])],1)}),0),_c('div',[_c('span',[_vm._v("測試題數約")]),_c('span',[_c('el-input',{model:{value:(_vm.testParams.question_amount),callback:function ($$v) {_vm.$set(_vm.testParams, "question_amount", $$v)},expression:"testParams.question_amount"}})],1),_c('span',[_vm._v("題")])]),_c('div',{staticClass:"group_btn"},_vm._l((_vm.btnList),function(item){return _c('div',{key:item.value,staticClass:"question_degree_btn"},[_c('el-button',{on:{"click":function($event){return _vm.changeNextType(item.value)}}},[_vm._v(_vm._s(item.name))])],1)}),0)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"combination_header"},[_c('div',{staticClass:"combination_title"},[_vm._v("補弱增强")]),_c('div')])}]

export { render, staticRenderFns }