<template>
  <div>
    <div class="combination_header">
      <div class="combination_title">補弱增强</div>
      <div></div>
      <!-- <div><span>張小明</span></div> -->
    </div>
    <div class="combination_test_content">
      <div class="combination_culum_content culum_bg">
        <div class="combination_sub_title">科目</div>
        <div
          class="culumn_content"
          v-for="subject in subjectList"
          :key="subject.subject_id"
          :class="{
            content_active: testSelectData.subject_id == subject.subject_id,
          }"
          @click="changeCulumnOrVersion('subject_id', subject.subject_id)"
        >
          {{ subject.subject_name }}
        </div>
      </div>
      <div class="combination_culum_content">
        <div class="combination_sub_title">版本</div>
        <div
          class="culumn_content"
          v-for="version in hsdVersionList"
          :key="version.version_id"
          :class="{
            content_active: testSelectData.version_id == version.version_id,
          }"
        >
          <!--@click="changeCulumnOrVersion('version_id', version.version_id)"-->
          {{ version.version_name }}
        </div>
      </div>
      <div class="combination_sub_content" v-loading="unitloading">
        <div class="combination_sub_title">類別/單元</div>
        <el-checkbox-group
          v-model="class_id_list"
          class="checkboxGroup"
          @change="changeProductClass"
        >
          <div
            v-for="classes in classList"
            :key="classes.class_id"
            class="checkbox_subject"
          >
            <el-checkbox :label="classes.class_id">
              {{ classes.class_name }}
            </el-checkbox>
            <div
              v-if="
                selectedClassId == classes.class_id &&
                classUnitList[selectedClassId]
              "
              class="checkbox_child_subject"
            >
              <el-checkbox-group
                v-model="unit_id_list"
                class="checkboxGroup"
                @change="changeClassUnit"
              >
                <el-checkbox
                  v-for="unit in classUnitList[selectedClassId]"
                  :label="unit.unit_id"
                  :key="unit.unit_id"
                >
                  {{ unit.unit_name }}
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </el-checkbox-group>
      </div>

      <div class="combination_topic_content">
        <div class="combination_sub_title">主題</div>
        <div class="topic_content">
          <div
            class="topic_child_content"
            :class="{ topic_active: topic_list.includes(topic.topic_id) }"
            v-for="topic in topicList"
            :key="topic.topic_id"
            @click="changeTopic(topic.topic_id)"
          >
            <div>
              {{ topic.topic_name }}<br />
              <i class="el-icon-success"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="combination_degree_content">
        <div class="group_btn">
          <div
            v-for="item in diffcultBtn"
            :key="item.value"
            class="question_degree_btn"
          >
            <el-button
              :class="{
                [`difficult-${item.value}`]: true,
                difficult_active: testParams.difficult == item.value,
              }"
              @click="changeDegree(item.value)"
              >{{ item.name }}</el-button
            >
          </div>
        </div>
        <div>
          <span>測試題數約</span>
          <span
            ><el-input v-model="testParams.question_amount"></el-input
          ></span>
          <span>題</span>
        </div>
        <div class="group_btn">
          <div
            v-for="item in btnList"
            :key="item.value"
            class="question_degree_btn"
          >
            <el-button @click="changeNextType(item.value)">{{
              item.name
            }}</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Message } from "element-ui";
import { createNamespacedHelpers, mapState } from "vuex";
import {
  getProductSubject,
  getProductClass,
  getProductVerssion,
  getProducUnit,
  getProducTopic,
  getProducTopicByClass,
  createUserTest,
  createTestPapers,
  createUserTestUnit,
  getCreateTestAfterOnManager,
  getCreateTestBeforeOnManager,
} from "../../api/api";
export default {
  data() {
    return {
      product: "JC",
      unitloading: false,
      testSelectData: { version_id: "ZZ" },
      testParams: { difficult: "1", question_amount: 20 }, //course_unit_id: ""单元组卷
      topic_list: [],
      // isIndeterminate: false,
      checkAll: false,
      unit_id_list: [],
      class_id_list: [],
      subjectList: [],
      classList: [],
      classUnitList: {},
      topicList: [],
      diffcultBtn: [
        { name: "難易適中", value: "3" },
        { name: "中間偏難", value: "5" },
        { name: "中間偏易", value: "1" },
      ],
      btnList: [
        { name: "確認組卷", value: "1" },
        { name: "重新出題", value: "2" },
        // { name: "開始測驗", value: "3" },
      ],
      selectedClassId: "",
    };
  },
  computed: {
    ...mapState(["hsdVersionList"]),
  },
  created() {
    this.getSubjectData();
    // this.getClassData();
  },
  methods: {
    handleCheckAllChange() {},
    handleCheckChange() {},
    //获取科目
    async getSubjectData() {
      const res = await getProductSubject({ product: this.product });
      if (res.success == 1) {
        this.subjectList = res.data;
      }
    },
    //获取类别
    async getClassData() {
      const { subject_id, version_id } = this.testSelectData;
      let params = Object.assign(
        { product: this.product },
        { subject_id, version_id }
      );
      const res = await getProductClass(params);
      if (res.success == 1) {
        this.classList = res.data;
      }
    },
    //选择版本、科目
    changeCulumnOrVersion(id, v) {
      this.classUnitList = [];
      this.$set(this.testSelectData, id, v);
      const { subject_id, version_id } = this.testSelectData;
      if (subject_id && version_id) {
        this.getClassData();
      }
    },
    //获取类别下的科目
    changeProductClass(val) {
      this.topic_list = [];
      this.unit_id_list = [];
      if (val && val.length) {
        let dv = val.length == 1 ? val : val.splice(-1);
        this.class_id_list = dv;
        this.selectedClassId = this.class_id_list[0];
        this.getTopicData();
        if (this.classUnitList[this.selectedClassId]) {
        } else {
          this.unitloading = true;
          this.getUnitData(this.selectedClassId);
        }
      } else {
        this.class_id_list = [];
      }
    },
    async getUnitData(class_id) {
      const res = await getProducUnit({ product: this.product, class_id });
      if (res.success == 1) {
        this.unitloading = false;
        this.$set(this.classUnitList, class_id, res.data);
      }
    },
    //选择科目、单元
    changeClassUnit(val) {
      this.topic_list = [];
      this.class_id_list = [];
      if (val && val.length) {
        let dv = val.length == 1 ? val : val.splice(-1);
        console.log(dv, val);
        this.unit_id_list = dv;
        this.testSelectData["unit_id"] = dv[0];
        this.getTopicData();
      } else {
        this.unit_id_list = [];
      }
    },
    //获取主题
    async getTopicData() {
      const { unit_id } = this.testSelectData;
      let params = Object.assign({
          product: this.product,
          class_id: this.selectedClassId,
        }),
        res = {};
      if (this.class_id_list.length) {
        res = await getProducTopicByClass(params);
      } else {
        //class_id_list==[] 为选择单元
        params.unit_id = unit_id;
        res = await getProducTopic(params);
      }

      if (res.success == 1) {
        this.topicList = res.data;
        // this.classUnitList[class_id]=res.data
      }
    },
    //选择考卷主题
    changeTopic(topic_id) {
      if (this.topic_list.includes(topic_id)) {
        let index = this.topic_list.indexOf(topic_id);
        this.topic_list.splice(index);
      } else {
        this.topic_list.push(topic_id);
      }
    },
    //选择组卷难度
    changeDegree(v) {
      this.$set(this.testParams, "difficult", v);
    },
    changeNextType(i) {
      const { subject_id } = this.testSelectData;
      if (i == "1") {
        const { question_amount } = this.testParams;
        this.testParams.question_amount = question_amount
          ? Number(question_amount)
          : 0;
        this.testParams.subject_id = subject_id;
        this.testParams.topic_list = JSON.parse(
          JSON.stringify(this.topic_list)
        );
        this.testParams.product = this.product;
        // console.log(this.testParams, this.topic_list);
        this.userStartTest();
      } else if (i == "2") {
        this.resetForm();
        this.resetData();
      } else {
      }
    },
    //组卷
    async userStartTest(params) {
      let queryData = {},
        search = location.search.substr(1),
        serchList = search ? search.split("&") : [];
      console.log(serchList);
      for (let i in serchList) {
        let serchItem = serchList[i].split("=");
        queryData[serchItem[0]] = serchItem[1];
      }
      // console.log(queryData);
      let res = {};
      if (queryData.type && queryData.type == "user") {
        res = await createUserTest(this.testParams);
      } else if (queryData.type && queryData.type == "test") {
        res = await createTestPapers(this.testParams);
      } else {
        const { learnPlanId, type } = queryData;
        let params = Object.assign({}, this.testParams, { learnPlanId });
        if (type && type == "after") {
          res = await getCreateTestAfterOnManager(params);
        } else {
          res = await getCreateTestBeforeOnManager(params);
        }
      }
      if (res.success == 1) {
        if (queryData.type && queryData.type == "user") {
          localStorage.setItem("test_paper_id", res.data.test_paper_id);
          localStorage.setItem("user_test_id", res.data.user_test_id);
          let newWindow=window.open('_blank')
          newWindow.location = "/startTest/index.html";
        } else {
          Message.success("組卷成功");
        }
      }
    },
    resetForm() {
      this.testSelectData = { version_id: "ZZ" };
      this.class_id_list = [];
      this.unit_id_list = [];
      this.topicList = [];
    },
    resetData() {
      this.testParams = { difficult: "1", question_amount: 20 };
    },
  },
};
</script>
<style>
.checkbox_subject label {
  display: block;
  width: auto;
}
.combination_header {
  width: 100%;
  padding: 15px 15px;
  font-size: 18px;
  background: #7dc8e4;
  color: #fff;
  display: flex;
  justify-content: space-between;
}
.combination_title {
  font-weight: 600;
}
.combination_sub_title {
  margin: 4px 6px;
  padding: 0 0 5px 5px;
  border-bottom: 1px solid #bdc0c3;
  font-weight: 600;
}
.combination_test_content {
  height: calc(100vh - 54px);
  display: flex;
  justify-content: space-around;
  font-weight: 500;
}
.combination_culum_content {
  /* width: 150px; */
  padding: 8px;
  background: #ababab;
}
.culum_bg {
  background: #a6bf9e;
}
.combination_sub_content {
  /* width: 160px; */
  height: 100%;
  padding: 8px;
  background: #cecece;
  overflow-y: scroll;
}
.combination_topic_content {
  flex: 1;
  padding: 8px;
  overflow-y: scroll;
}

.topic_content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.topic_child_content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 30%;
  margin: 5px;
  /* padding: 80px 50px; */
  background: #757b7a;
  color: #fff;
  text-align: center;
}

.combination_degree_content {
  width: 150px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.culumn_content {
  padding: 6px 15px;
  /* background: #d2f537; */
}
.culumn_content:hover {
  background: #fcff63;
}
.content_active {
  background: #fcff63;
}
.checkbox_subject label {
  width: 100%;
  padding: 6px 15px !important;
}
.checkbox_subject label:hover {
  background: #fcff63;
}
.checkbox_child_subject {
  margin-left: 14px;
}

.checkbox_child_subject label:hover {
  background: #fcff63;
}
.question_degree_btn {
  margin: 12px 0;
}
.question_degree_btn :hover,
.question_degree_btn :active {
}
.difficult-1 {
  border: none;
  background: chartreuse;
}
.difficult-3 {
  border: none;
  background: #7cfdfd;
}
.difficult-5 {
  border: none;
  background: #e67524;
}
.topic_active {
  color: #fdde00;
}
.difficult_active {
  color: #409eff;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}
.el-input {
  width: 70%;
  margin: 12px 5px 0 0;
}
</style>